.box {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
}

.el {
    width: 32%;
    margin-bottom: 2%;
}

@media (max-width: 750px) {
    .el {
        width: 48%;
        margin-bottom: 4%;
    }
}