
.seeMore {

}

.content {
    grid-area: 1/left/1/right;
}

.arrowBox {
    text-decoration: none;
    color: inherit;
    display: grid;
    grid-template-columns: 1fr auto 1fr;
    grid-template-areas: "left mid right";
}

.arrowBox:visited {
    color: inherit;
}


.text {
    grid-area: mid;
    font-size: var(--h1Size);
    font-weight: bold;

}

@media (max-width: 750px) {
    .text {
        font-size: 1.5em; 

    }
}	

.left {
    grid-area: left;
    display: flex;
    align-items: center;
    transform: scale(-1);
    padding-left: 12.5px;
}

.right {
    grid-area: right;
    display: grid;
    grid-template-columns: auto;
    grid-template-areas: "head";
    align-items: center; 
    padding-left: 12.5px;

}

.arrow {
    grid-area: head;
    height: calc(var(--h1Size) * 0.75);
    width: 100%;
    overflow: visible;
}