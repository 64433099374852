.mainGrid {
	display: grid;
    grid-template-columns: 1fr 50px 1100px 50px 1fr;	  
	grid-template-areas: ". left mid right .";
}
	
@media (max-width: 1920px) {
    .mainGrid {
        grid-template-columns: 1fr 50px 900px 50px 1fr;	
    }   
}


@media (max-width: 1100px) {
    .mainGrid {
        grid-template-columns: 50px 50px 1fr 50px 50px;	
    }   
}	

@media (max-width: 750px) {
    .mainGrid {
        grid-template-columns: 25px auto auto auto 25px;	
        grid-template-areas: ". left mid right .";
    }   
}	
    