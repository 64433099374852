.welcome {
    grid-area: 1 / left / 1 / right;
    height: 750px;
    display: grid;
    grid-template-columns: 1fr auto 50px auto 1fr;
    grid-template-areas: ". img . text .";
    justify-content: center;
    align-items: center;


}

.text {
    grid-area: text;
    max-width: 400px;
    font-size: 1.5em;
    font-weight: 400;
}

.img {
    grid-area: img;
    width: 400px;
    height: 400px;
}

@media (max-width: 1100px) {
    .welcome {
        height: 500px;
    }
    .img {
        width: 300px;
        height: 300px;
    }
}	

@media (max-width: 750px) {
    .img {
        width: 100%;
        height: auto;
        object-fit: cover;
    }
    .welcome {
        height: auto;
        grid-template-columns: auto;
        grid-template-rows: auto 50px;
        grid-template-areas: "img" "." "text";
        justify-content: center;
        align-items: center;
    }
    .text {
        justify-self: center;
        width: 100%;
        text-align: center;
    }
}	