

.line {
    border-top: 3px solid black;
    grid-area: left;
}

.text {
    grid-area: 1/left/1/Right;
    font-family: var(--main-font);
    font-weight: 600;


}

@media (max-width: 750px) {
    .line {
        width: 75px;
        justify-self: center;
        grid-area: mid;
    }
    .text {
        justify-self: center;
        grid-area: mid;
    }
}	