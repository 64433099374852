.projectCard {
    grid-area: 1 / left / 1 / right;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 300px;
    grid-template-areas: "thumb desc";
    overflow: hidden;
    border-radius: var(--borderRadius);
    cursor: pointer;
    transition: transform var(--transitionDuration), box-shadow 0.3s;
    height: 375px;
}

.projectCard:hover {
    transform: scale(1.01, 1.01);
    box-shadow: 0 0  4px 0px rgba(0, 0, 0, 0.6);
}

.projectCard:active {
    transform: scale(1.01, 1.01);
    box-shadow: 0 0  4px 0px rgba(0, 0, 0, 0.6);
}


.thumbnail {
    grid-area: 1 / thumb / 1 / desc;
    object-fit: cover;
    object-position: center center;
    background-color: grey;
}

.description {
    grid-area: desc;
    background-color: green;
    box-sizing: border-box;
    padding-left: 50px;
    padding-right: 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
 
@media (max-width: 750px) {
    .projectCard {
        grid-template-columns: 1fr;
        grid-template-rows: auto auto;
        grid-template-areas: "thumb" "desc";
        height: initial;

    }
    .thumbnail {
        height: 250px;
        width: 120%;
    }

}	
	
@media (max-width: 500px) {
    .projectCard {
        grid-template-rows: auto auto;
    }
    .thumbnail {
        height: 150px;
        width: 130%;
    }
}	
	
    