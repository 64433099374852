
.header {
    position: fixed;
    width: 100%;
    height: 60px;
    background-color: white;
    z-index: 99;
}

.slider {
    z-index: 99;  
}

.headerInside {
    grid-area: 1 / left / 1 / right;
    display: grid;
    align-items: center;
    grid-template-columns: auto 1fr auto;
    grid-template-areas: "name . nav";
}

.headerCushion {
    width: 100%;
    height: 60px;
}

.name {
    grid-area: name;
    cursor: pointer;
    font-family: var(--main-font);
    font-weight: 600;
    text-decoration: none;
}


.name:visited {
    color: inherit;
}

.nav {
    cursor: pointer;
    grid-area: nav;
}

