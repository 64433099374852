:root {
  --main-font: forma-djr-deck, sans-serif;
  --borderRadius: 10px;
  --transitionDuration: 0.3s;
}

body {
  max-width: 100%;
  overflow-x: hidden;
}

h1 {
  margin: 0;
}
h2 {
  margin: 0;
}
h3 {
  margin: 0;
}

p {
  margin: 0;
  max-width: 400px;

}