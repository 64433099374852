

.aLink {
    text-decoration: none;
    color: inherit;
}

.aLink:visited {
    color: inherit;
}

.aLinkThin span {
    --thickness: 2px;
}

.aLinkThick span{
    --thickness: 5px;
}


.aLink span {
    background-size: 0 var(--thickness);
    background-position: 0 100%;
    background-repeat: no-repeat;
    transition: background-size var(--transitionDuration);
}

.aLink_white span {
    background-image: linear-gradient(90deg, white, white);
}

.aLink_black span {
    background-image: linear-gradient(90deg, black, black);
}

.aLink:hover span {
    background-size: 100% var(--thickness);
}

.aLink:active span {
    background-size: 100% var(--thickness);
}

