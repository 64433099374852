
body {
    font-family: 'Roboto', sans-serif;
    font-size: 18px;
    --h1Size: 2.5em;
}
  
h1 {
    font-family: var(--main-font);
    font-size: var(--h1Size);
}

h2 {
    font-family: var(--main-font);
}

h3 {
    font-family: var(--main-font);
}

p {
    font-family: 'Roboto', sans-serif;
    font-weight: 300;
    font-size: 16px;
}


@media (max-width: 750px) {
    body {
        font-family: 'Roboto', sans-serif;
        font-size: 18px;
        --h1Size: 2em;
    }
    
    h2 {
        font-size: 1.17em,
    }
    
    h3 {
        font-size: 1em,
    }
}