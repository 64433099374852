
.svgCanvas {
    width: 40px;
    height: 40px;
}

.line {
    stroke: black;
    stroke-width: 2.5;
}


